import axios from 'axios';

const customAxios = axios.create({
  baseURL: 'https://iamgovindthakur-portfolio.onrender.com', // Set the base URL for all requests
  timeout: 5000, // Set a default timeout for requests (optional)
  headers: {
    'Content-Type': 'application/json', // Set default headers for all requests (optional)
  },
});

export default customAxios;
