import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { authenticateAdmin } from '../actions'
import { useNavigate } from 'react-router-dom';
const LoginScreen = (props) => {
  const [show, setShow] = useState(true)
  const [secreteKey, setSecreteKey] = useState()

  const handleClose = () => setShow(false)

  const navigate = useNavigate();

  const modalShowHandler = (value) => {
    props.onAlter(value)
  }
  const dispatch = useDispatch()

  const submitFormHandler = (e) => {
    e.preventDefault()
    dispatch(authenticateAdmin(secreteKey, navigate))
  }

  // useEffect(() => {
  //   const userInfo = JSON.parse(localStorage.getItem('userInfo'))
  //   if (!userInfo) document.location.href = '/newProject'
  // }, [])

  return (
    <Modal
      size='lg'
      show={show}
      onHide={(handleClose, modalShowHandler.bind(this, false))}
    >
      <Modal.Header closeButton>
        <Modal.Title>Prove Your Identity</Modal.Title>
      </Modal.Header>

      <Form style={{ marginTop: '20px' }}>
        <Form.Group controlId='formGroupPassword'>
          <Form.Label className='d-flex justify-content-center'>
            Password
          </Form.Label>
          <Form.Control
            type='password'
            placeholder='Password'
            onChange={(event) => setSecreteKey(event.target.value)}
            className='text-center'
          />
        </Form.Group>
        <div className=' text-center mb-3'>
          <Button type='submit' variant='dark' onClick={submitFormHandler}>
            Authenticate
          </Button>
        </div>
      </Form>
    </Modal>
  )
}

export default LoginScreen
