import { FETCH_CERTIFICATES } from '../actions/types'

export default function certificatesReducer (state = { certificates: [] }, action) {
  switch (action.type) {
    case FETCH_CERTIFICATES:
      return { certificates: action.payload.reverse() }
    default:
      return state
  }
}
