import { FETCH_PROJECTS, FETCH_PROJECT_BY_ID } from '../actions/types'

export default function projectReducer (state = { projects: [], project: {} }, action) {
  switch (action.type) {
    case FETCH_PROJECTS:
      return { ...state, projects: action.payload }
    case FETCH_PROJECT_BY_ID:
      return { ...state, project: action.payload }
    default:
      return state
  }
}
