import React, { useState, useEffect } from 'react'
import { Form, Row, Col, Button, Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { addCertificate } from '../actions'

const AddCertificate = ({ navigate }) => {
  const [certificate, setCertificate] = useState({
    name: null,
    organization: null,
    url: null,
    thumbnail: null,
  })
  const dispatch = useDispatch()

  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem('userInfo'))
    if (!userInfo) navigate('/')
  }, [navigate])

  const formSubmitHandler = (e) => {
    e.preventDefault()
    dispatch(addCertificate(certificate, navigate))
  }

  const [show, setShow] = useState(true)
  const handleClose = () => setShow(false)

  return (
    <Modal
      size='lg'
      show={show}
      onHide={handleClose}
      backdrop='static'
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Certificate</Modal.Title>
      </Modal.Header>
      <div className='d-flex justify-content-center my-3'>
        <Form onSubmit={formSubmitHandler}>
          <Form.Group as={Row} controlId='formPlaintextEmail'>
            <Form.Label column sm='4'>
              Name
            </Form.Label>
            <Col sm='8'>
              <Form.Control
                onChange={(event) =>
                  setCertificate({ ...certificate, name: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='formPlaintextPassword'>
            <Form.Label column sm='4'>
              Organization
            </Form.Label>
            <Col sm='8'>
              <Form.Control
                onChange={(event) =>
                  setCertificate({
                    ...certificate,
                    organization: event.target.value,
                  })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='formPlaintextEmail'>
            <Form.Label column sm='4'>
              Url
            </Form.Label>
            <Col sm='8'>
              <Form.Control
                onChange={(event) =>
                  setCertificate({ ...certificate, url: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId='formPlaintextPassword'>
            <Form.Label column sm='4'>
              Thumbnail
            </Form.Label>
            <Col>
              <Form.Control
                onChange={(event) =>
                  setCertificate({
                    ...certificate,
                    thumbnail: event.target.value,
                  })
                }
              />
            </Col>
          </Form.Group>
          <div className=' text-center'>
            <Button type='submit' variant='dark'>
              Submit
            </Button>
          </div>
        </Form>
      </div>
    </Modal>
  )
}

export default AddCertificate
