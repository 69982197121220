import { combineReducers } from 'redux'
import projectReducer from './projectReducer'
import certificateReducer from './certificateReducer'
import authReducer from './authReducer'

export default combineReducers({
  projects: projectReducer,
  certificates: certificateReducer,
  user: authReducer,
})
