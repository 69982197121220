import React, { useEffect } from 'react'
import projectStyles from './project.module.css'
import Card from '../UX/Card'
import Loader from '../UX/Loader'
import { useDispatch, useSelector } from 'react-redux'
import { fetchProjects } from '../actions'
import { Link } from 'react-router-dom'

const Project = ({ history }) => {
  const dispatch = useDispatch()
  const loadedProjects = useSelector((state) => state.projects.projects)

  useEffect(() => {
    dispatch(fetchProjects())
  }, [dispatch])

  const displayHandler = (
    <div className='py-3'>
      <div className={`${projectStyles.projectHolder}`}>
        {loadedProjects.map((project, index) => {
          return (
            <Link
              to={`/project/${project._id}`}
              style={{ color: 'inherit', textDecoration: 'none' }}
              key={index}
            >
              <Card key={index}>
                <img
                  src={project.image}
                  alt='project'
                  height={225}
                  width={345}
                  style={{ marginBottom: '20px' }}
                />
                <p
                  style={{
                    fontSize: '25px',
                    fontWeight: 'bold',
                  }}
                >
                  {project.title}
                </p>
              </Card>
            </Link>
          )
        })}
      </div>
    </div>
  )

  return (
    <>
      <div className='row'>
        <div className='col'>
          <h1 className='text-center text-info'>My Projects</h1>
        </div>
      </div>
      {loadedProjects.length > 0 ? displayHandler : <Loader />}
    </>
  )
}

export default Project
