import React, { useState } from "react";

import { Modal } from "react-bootstrap";

const ModalView = (props) => {
  const [show, setShow] = useState(true);

  const handleClose = () => setShow(false);

  const modalShowHandler = (value) => {
    if(props.item==='aboutShow')
    props.onAlter('aboutShow',value);
    else
    props.onAlter('hireShow',value);
  };

  return (
    <>
      <Modal
        show={show}
        onHide={(handleClose, modalShowHandler.bind(this, false))}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props.title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{props.body}</Modal.Body>
        <Modal.Footer>
          <p>Thanks for checking me out!</p>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalView;
