import React from "react";
import Header from "../src/componenets/Header";
import Poster from "../src/componenets/Poster";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Project from "./componenets/Project";
import Certificate from "./componenets/Certificate";
import ProjectDetailScreen from "./componenets/ProjectDetailScreen";
import AddProject from "./componenets/AddProject";
import AddCertificate from "./componenets/AddCertificate";

const App = () => {
  return (
    <>
      <Router>
        <Header />
        <Poster />
        <Certificate />
        <Routes>
          <Route path="/project/:pid" element={<ProjectDetailScreen />} />
          <Route path="/newProject" element={<AddProject />} />
          <Route path="/newCertificate" element={<AddCertificate />} />
          <Route path="/" element={<Project />} />
        </Routes>
      </Router>
    </>
  );
};

export default App;
