import axios from "../axiosInstance";
import {
  FETCH_CERTIFICATES,
  FETCH_PROJECTS,
  FETCH_PROJECT_BY_ID,
  AUTHENTICATE_ADMIN,
} from "./types";

export const fetchCertificates = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/certificates");
    dispatch({ type: FETCH_CERTIFICATES, payload: res.data.certificates });
  } catch (error) {
    console.error("Error fetching certificates:", error);
  }
};

export const fetchProjects = () => async (dispatch) => {
  try {
    const res = await axios.get("/api/projects");
    dispatch({ type: FETCH_PROJECTS, payload: res.data.projects });
  } catch (error) {
    console.error("Error fetching certificates:", error);
  }
};

export const fetchProjectById = (pid) => async (dispatch) => {
  const res = await axios.get(`/api/project/${pid}`);
  dispatch({ type: FETCH_PROJECT_BY_ID, payload: res.data.project });
};

export const authenticateAdmin = (secreteKey, navigate) => async (dispatch) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  const res = await axios.post("/login", { secreteKey }, config);
  dispatch({ type: AUTHENTICATE_ADMIN, payload: res.data });
  localStorage.setItem("userInfo", JSON.stringify(res.data));
  if (res.status === 200) navigate("/newProject");
};

export const addProject = (project, navigate) => async (dispatch) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
  };
  try {
    await axios.post("/api/project", { project }, config);
  } finally {
    navigate("/");
  }
};

export const addCertificate = (certificate, navigate) => async (dispatch) => {
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${userInfo.token}`,
    },
  };

  try {
    await axios.post("/api/certificate", { certificate }, config);
  } finally {
    navigate("/");
  }
};
