import React, { useState } from 'react'
import { Document, Page, pdfjs } from 'react-pdf'
import { Modal } from 'react-bootstrap'
import samplepdf from '../util/resume.pdf'
const Resume = (props) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
  const [numPages, setNumPages] = useState(null)
  const [pageNumber, setPageNumber] = useState(1)

  const [show, setShow] = useState(true)

  const handleClose = () => setShow(false)
  const modalShowHandler = (value) => {
    props.onAlter(value)
  }

  /*To Prevent right click on screen*/
  document.addEventListener('contextmenu', (event) => {
    event.preventDefault()
  })

  /*When document gets loaded successfully*/
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages)
    setPageNumber(1)
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset)
  }

  function previousPage() {
    changePage(-1)
  }

  function nextPage() {
    changePage(1)
  }

  return (
    <>
      <Modal
        size='lg'
        show={show}
        onHide={(handleClose, modalShowHandler.bind(this, false))}
      >
        <Modal.Body>
          <Modal.Header closeButton>
            <Modal.Title>My Resume</Modal.Title>
          </Modal.Header>

          <Document file={samplepdf} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={pageNumber} />
          </Document>
        </Modal.Body>
        <div class='card-footer text-center'>
          <div>
            Page {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
          </div>
          <div>
            <button
              className='btn btn-theme'
              style={{ marginRight: '10px' }}
              type='button'
              disabled={pageNumber <= 1}
              onClick={previousPage}
            >
              <i class='fa fa-chevron-left fa-2x' aria-hidden='true'></i>
            </button>
            {/* <i class="fa fa-file-pdf-o fa-2x" aria-hidden="true"></i> */}
            <button
              class='btn btn-theme'
              type='button'
              disabled={pageNumber >= numPages}
              onClick={nextPage}
            >
              <i class='fa fa-chevron-right fa-2x' aria-hidden='true'></i>
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Resume
