import React, { useState } from 'react'
import { Navbar, Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'
import ModalView from '../UX/ModalView'
import LoginScreen from './LoginScreen'
import styles from './nav.module.css'
import Resume from './Resume'

const Header = () => {
  const [aboutShow, setAboutShow] = useState()
  const [hireShow, setHireShow] = useState()
  const [resumeShow, setResumeShow] = useState()
  const [adminShow, setAdminShow] = useState()

  const handleAboutShow = () => setAboutShow(true)
  const handleHireShow = () => setHireShow(true)
  const handleResumeShow = () => setResumeShow(true)
  const handleAdminShow = () => setAdminShow(true)

  const modalHandler = (item, value) => {
    if (item === 'aboutShow') setAboutShow(value)
    else setHireShow(value)
  }

  const resumeHandler = (value) => {
    setResumeShow(value)
  }

  const adminHandler = (value) => {
    setAdminShow(value)
  }

  const about_body = (
    <>
      <h3>Technical Skills</h3>
      <ul>
        <li>Node Js</li>
        <li>React Js</li>
        <li>React-Native</li>
        <li>MongoDb</li>
        <li>Express</li>
        <li>Python</li>
        <li>C</li>
        <li>java</li>
        <li>PhP</li>
        <li>HTML5</li>
        <li>CSS</li>
        <li>Jquery</li>
        <li>Javascript</li>
        <li>Sql</li>
      </ul>
    </>
  )

  const hire_body = (
    <>
      <h3>I'm for Hire :-)</h3>
      <p>
        You can connect with me on Twitter, LinkedIn,Facebook or GitHub below.
      </p>
      <ul className={styles.list_align}>
        <li>
          <a href='https://twitter.com/iamgovindthakur' target='_blank' rel='noopener noreferrer'>
            <i class='fa fa-twitter fa-2x'></i>
          </a>
        </li>
        <li>
          <a href='https://github.com/iamgovindthakur' target='_blank' rel='noopener noreferrer'>
            <i class='fa fa-github fa-2x'></i>
          </a>
        </li>
        <li>
          <a href='https://facebook.com/iamgovindthakur' target='_blank' rel='noopener noreferrer'>
            <i class='fa fa-facebook fa-2x'></i>
          </a>
        </li>
        <li>
          <a
            href='https://www.linkedin.com/in/iamgovindthakur/'
            target='_blank' rel='noopener noreferrer'
          >
            <i class='fa fa-linkedin fa-2x'></i>
          </a>
        </li>
      </ul>
    </>
  )

  return (
    <>
      <Navbar
        className={`${styles.color_nav}`}
        variant='dark'
        expand='lg'
        fixed='top'
      >
        <Navbar.Brand>Govind Kumar Thakur</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className='mr-auto'>
            <LinkContainer to='/'>
              <Nav.Link>Home</Nav.Link>
            </LinkContainer>
          </Nav>
          <Nav fill className='justify-content-end'>
            {/* <LinkContainer to='/login'> */}
            <Nav.Link className='nav-link ' onClick={handleAdminShow}>
              Admin
            </Nav.Link>

            {/* </LinkContainer> */}
            {adminShow && <LoginScreen onAlter={adminHandler} />}
            <Nav.Link onClick={handleResumeShow}>View My Resume</Nav.Link>
            {resumeShow && <Resume onAlter={resumeHandler} />}
            <Nav.Link>
              <p onClick={handleHireShow}>Hire Me</p>
            </Nav.Link>
            {hireShow && (
              <ModalView
                title='Woo-hoo!'
                body={hire_body}
                onAlter={modalHandler}
                item='hireShow'
              />
            )}
            <Nav.Link>
              <p onClick={handleAboutShow}>About</p>
            </Nav.Link>
            {aboutShow && (
              <ModalView
                title='About Me'
                body={about_body}
                onAlter={modalHandler}
                item='aboutShow'
              />
            )}
            <Nav.Item>
              <Nav.Link
                href='https://twitter.com/iamgovindthakur'
                target='_blank'
              >
                {' '}
                <i className='fa fa-twitter'></i>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href='https://github.com/iamgovindthakur'>
                {' '}
                <i className='fa fa-github'></i>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href='https://www.linkedin.com/in/iamgovindthakur'
                target='_blank'
              >
                {' '}
                <i className='fa fa-linkedin'></i>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                href='https://www.facebook.com/iamgovindthakur'
                target='_blank'
              >
                {' '}
                <i className='fa fa-facebook'></i>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}

export default Header
