import React from 'react'
import posterLogo from '../images/govind_kumar.jpg'
const Poster = () => {
  return (
    <div className='mt-3'>
      <div className='jumbotron animated fadeIn'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <h2>Hi, I'm Govind Kumar Thakur!</h2>
              <p>
                I am a Senior Software Engineer Working at Morgan Stanley.
              </p>
            </div>
            <div className='col-md-4'>
              <img
                src={posterLogo}
                alt='Responsive'
                className='rounded-circle'
                width='100%'
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Poster
