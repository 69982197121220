import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Row, Col, Button, Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addProject } from "../actions";

const AddProject = () => {
  const [project, setProject] = useState({
    title: null,
    image: null,
    info: null,
    technology: null,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    const userInfo = JSON.parse(localStorage.getItem("userInfo"));
    if (!userInfo) navigate("/");
  }, [navigate]);

  const formSubmitHandler = (e) => {
    e.preventDefault();
    dispatch(addProject(project, navigate));
  };

  const [show, setShow] = useState(true);
  const handleClose = () => setShow(false);

  return (
    <Modal
      size="lg"
      show={show}
      onHide={handleClose}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>Add New Project</Modal.Title>
      </Modal.Header>

      <div className="d-flex justify-content-center my-3">
        <Form onSubmit={formSubmitHandler}>
          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Title
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="textarea"
                onChange={(event) =>
                  setProject({ ...project, title: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPlaintextPassword">
            <Form.Label column sm="4">
              Image
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="textarea"
                onChange={(event) =>
                  setProject({ ...project, image: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPlaintextEmail">
            <Form.Label column sm="4">
              Info
            </Form.Label>
            <Col sm="8">
              <Form.Control
                as="textarea"
                onChange={(event) =>
                  setProject({ ...project, info: event.target.value })
                }
              />
            </Col>
          </Form.Group>

          <Form.Group as={Row} controlId="formPlaintextPassword">
            <Form.Label column sm="4">
              Technology
            </Form.Label>
            <Col>
              <Form.Control
                as="textarea"
                onChange={(event) =>
                  setProject({ ...project, technology: event.target.value })
                }
              />
            </Col>
          </Form.Group>
          <div className=" text-center">
            <Button type="submit" variant="dark">
              Submit
            </Button>
          </div>
        </Form>
      </div>
      <Modal.Footer className="d-flex justify-content-end">
        <p>Add Certificate</p>
        <Link to="/newCertificate">
          <i className="fa fa-plus-circle fa-3x"/>
        </Link>
      </Modal.Footer>
    </Modal>
  );
};

export default AddProject;
