import React, { useEffect } from "react";
import { fetchProjectById } from "../actions";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Loader from "../UX/Loader";
import { Link } from "react-router-dom";

const ProjectDetailScreen = () => {
  const { pid } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { project } = useSelector((state) => state.projects);

  useEffect(() => {
    dispatch(fetchProjectById(pid));
    if (project.title) document.title = `${project.title}`;
  }, [dispatch,pid,project.title]);

  const back = () => {
    navigate(-1);
  };

  return (
    <>
      {Object.keys(project).length > 0 ? (
        <div className="d-flex flex-column justfy-content-center">
          <center>
            <img
              src={project.image}
              alt="project"
              height={225}
              width={345}
              style={{ marginBottom: "20px" }}
            />

            <p style={{ fontSize: "25px", fontWeight: "bold" }}>
              {project.title}
            </p>
            <p>
              {" "}
              <strong>{project.info}</strong>
            </p>
            <h4>Technologies Used</h4>
            <ul
              key={project.image}
              style={{ listStylePosition: "inside" }}
              className="list-group"
            >
              {project.technology.map((tech) => {
                return (
                  <li key={tech} className="list-group-item border-0">
                    {tech}
                  </li>
                );
              })}
            </ul>
          </center>
          <div style={{ textAlign: "center" }}>
            <Link
              onClick={back}
              style={{
                color: "inherit",
                textDecoration: "none",
              }}
            >
              <i
                className="fa fa-arrow-circle-left fa-5x"
                aria-hidden="true"
              ></i>
            </Link>
          </div>
        </div>
      ) : (
        <Loader />
      )}
    </>
  );
};

export default ProjectDetailScreen;
