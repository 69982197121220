import React, { useEffect } from 'react'
import { Card, Button } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { fetchCertificates } from '../actions'
import Loader from '../UX/Loader'

const Certificates = () => {
  const dispatch = useDispatch()
  const loadedCertificates = useSelector(
    (state) => state.certificates.certificates
  )

  useEffect(() => {
    dispatch(fetchCertificates())
  }, [dispatch])

  const displayHandler = (
    <>
      <h4 style={{ textAlign: 'center', marginBottom: '20px' }}>
        My Certificates & Accomplishment
      </h4>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          overflowX: 'auto',
          height: '56vh',
        }}
      >
        {loadedCertificates.map(
          ({ name, url, organization, thumbnail }, index) => {
            return (
              <div style={{ margin: '0 10px' }} key={index}>
                <Card
                  key={index}
                  border='success'
                  style={{
                    marginRight: '20px',
                  }}
                >
                  <Card.Header
                    style={{
                      textAlign: 'center',
                      color: organization === 'Udemy' ? '#EA5252' : '#2A73CC',
                    }}
                  >
                    {organization}
                  </Card.Header>
                  <Card.Body>
                    <Card.Title>{name}</Card.Title>
                    <img
                      src={thumbnail}
                      alt='course_image'
                      style={{
                        width: '240px',
                        height: '135px',
                        marginBottom: '10px',
                      }}
                    />
                    <div style={{ textAlign: 'center' }}>
                      <Button href={url} target='_blank' variant='primary'>
                        View Certificates
                      </Button>
                    </div>
                  </Card.Body>
                </Card>
              </div>
            )
          }
        )}
      </div>
    </>
  )

  return <>{loadedCertificates.length > 0 ? displayHandler : <Loader />}</>
}

export default Certificates
