import { AUTHENTICATE_ADMIN } from "../actions/types";

const userInfoFromStorage = localStorage.getItem("userInfo")
  ? JSON.parse(localStorage.getItem("userInfo"))
  : null;

const authReducer = (state = { ...userInfoFromStorage }, action) => {
  switch (action.type) {
    case AUTHENTICATE_ADMIN:
      return { ...state, userInfo: action.payload };

    default:
      return state;
  }
};

export default authReducer;
